import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import VisibilitySensor from 'react-visibility-sensor';

import { AuthContext } from '../../shared/context/AuthContext';

const Home = () => {
	//  ------------------------------------------------------------------------
	//  ****** Page Data and State
	//  ------------------------------------------------------------------------
	const auth = React.useContext(AuthContext);
	const theme = useTheme();

	//  ------------------------------------------------------------------------
	//  ****** Scroll to top of page when opening
	//  ------------------------------------------------------------------------
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	//  ------------------------------------------------------------------------
	//  ****** Show/hide top logo depending on whether heading logo is visible
	//  ------------------------------------------------------------------------
	function headingLogoVisibilityChanged(isVisible) {
		auth.setShowAppBarLogo(!isVisible);
	}

	// #Content
	//  ------------------------------------------------------------------------
	//  ****** Page content
	//  ------------------------------------------------------------------------
	return (
		<>
			{/* ---------------------------- */}
			{/* 		Title 				 */}
			{/* ---------------------------- */}
			<Grid
				container
				p={0}
				rowSpacing={0}
				columnSpacing={0}
				sx={{ backgroundColor: theme.palette.primary.main }}
			>
				<Grid item xs={12} align="center" sx={{ pt: 4, pb: 5, pl: 6, pr: 6 }}>
					<Typography variant={'h5'} color={theme.palette.primary.veryLight}>
						----------------------------
					</Typography>
					<Typography variant={'h5'} color={theme.palette.primary.veryLight}>
						Coming Soon
					</Typography>
					<Typography variant={'h5'} color={theme.palette.primary.veryLight}>
						----------------------------
					</Typography>
					<br />
					<VisibilitySensor onChange={headingLogoVisibilityChanged}>
						<img
							src={
								process.env.REACT_APP_LEGALITREE_AWS_PUBLIC + '/LT White M.png'
							}
							alt="Logo"
							height="80"
						/>
					</VisibilitySensor>

					<br />
					<br />
					<Typography variant={'h4'} color={theme.palette.primary.text}>
						Legalitree
					</Typography>
					<Typography variant={'h5'} color={theme.palette.primary.text}>
						The Legal Marketplace
					</Typography>
					<Typography color={theme.palette.primary.text}>
						Connecting Clients and Legal Professionals
					</Typography>
					<br />
					<Typography variant={'h5'} color={theme.palette.primary.veryLight}>
						----------------------------
					</Typography>
					<Typography variant={'h5'} color={theme.palette.primary.veryLight}>
						Coming Soon
					</Typography>
					<Typography variant={'h5'} color={theme.palette.primary.veryLight}>
						----------------------------
					</Typography>
				</Grid>
			</Grid>

			{/* ---------------------------- */}
			{/* 		Intro				 */}
			{/* ---------------------------- */}
			<Grid
				container
				p={0}
				rowSpacing={0}
				columnSpacing={0}
				justifyContent={'center'}
				alignItems={'center'}
			>
				<Grid item xs={0} lg={1.5} order={{ xs: 1 }}></Grid>
				<Grid
					item
					xs={12}
					md={8}
					lg={6}
					sx={{ pt: { xs: 3, md: 5 }, pb: 5, pl: 5, pr: 5 }}
					order={{ xs: 3, md: 2 }}
					align="center"
				>
					<Typography variant={'h6'} align="center" color={'primary'}>
						Legalitree is a legal marketplace
					</Typography>
					<br />
					<Typography>
						We connect clients to legal professionals quickly and easily.
					</Typography>
					<br />
					<Typography>
						Clients with legal projects use Legalitree to find lawyers and
						patent agents.
					</Typography>
					<br />
					<Typography>
						Legal professionals use Legalitree to find new clients, as well as
						to find paralegals and legal assistants.
					</Typography>
					<br />
				</Grid>

				<Grid
					item
					xs={12}
					md={4}
					lg={3}
					order={{ xs: 2, md: 3 }}
					align="center"
					sx={{ pt: 5, pb: { xs: 0, md: 5 }, pl: 10, pr: 10 }}
				>
					<img
						src={
							process.env.REACT_APP_LEGALITREE_AWS_PUBLIC + '/Marketplace.svg'
						}
						alt="Connected network of people"
						width="185"
					/>
				</Grid>

				<Grid item xs={0} lg={1.5} order={{ xs: 4 }}></Grid>
			</Grid>

			{/* ---------------------------- */}
			{/* 		Clients 			 */}
			{/* ---------------------------- */}
			<Grid
				container
				p={0}
				rowSpacing={0}
				columnSpacing={0}
				justifyContent={'center'}
				alignItems={'center'}
				sx={{ backgroundColor: theme.palette.primary.veryLight }}
			>
				<Grid item xs={0} lg={1.5} order={{ xs: 1 }}></Grid>
				<Grid
					item
					xs={12}
					md={8}
					lg={6}
					sx={{ pt: { xs: 3, md: 5 }, pb: 5, pl: 5, pr: 5 }}
					order={{ xs: 3, md: 3 }}
					align="center"
				>
					<Typography variant={'h6'} align="center" color={'primary'}>
						Looking for a lawyer?
					</Typography>
					<Typography>
						Look no further. Legalitree can connect you with a lawyer of your
						choosing. Just tell us what you need, and lawyers on our platform
						will respond to bid on your project. When you receive a bid from a
						lawyer you like, just accept the bid, pay securely via the
						Legalitree platform, and you're on your way!
					</Typography>
					<br />
					<Typography>
						No need to get recommendations, chase down lawyers, or make
						appointments. Legalitree solves all of the difficulties usually
						associated with finding a lawyer. We make it quick and easy to
						connect with multiple lawyers and choose the one you like.
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					md={4}
					lg={3}
					order={{ xs: 2, md: 2 }}
					align="center"
					sx={{ pt: 5, pb: { xs: 0, md: 5 }, pl: 10, pr: 10 }}
				>
					<img
						src={process.env.REACT_APP_LEGALITREE_AWS_PUBLIC + '/Client.svg'}
						alt="Handshake"
						width="185"
					/>
				</Grid>

				<Grid item xs={0} lg={1.5} order={{ xs: 4 }}></Grid>
			</Grid>

			{/* ---------------------------- */}
			{/* 	Legal Professionals      */}
			{/* ---------------------------- */}
			<Grid
				container
				p={0}
				rowSpacing={0}
				columnSpacing={0}
				justifyContent={'center'}
				alignItems={'center'}
			>
				<Grid item xs={0} lg={1.5}></Grid>

				<Grid
					item
					xs={12}
					md={8}
					lg={6}
					sx={{ pt: { xs: 3, md: 5 }, pb: 5, pl: 5, pr: 5 }}
					order={{ xs: 3, md: 2 }}
					align="center"
				>
					<Typography variant={'h6'} color={'primary'}>
						For lawyers and other legal professionals
					</Typography>
					<Typography align="center">
						Legalitree provides you with a marketplace of new clients looking
						for your help on their projects. Submit a bid for a project and if
						your bid is chosen, you have a new client! Let Legalitree replace
						your time and effort on laborious and long-shot marketing activities
						so you can focus on what you do best - practicing law and serving
						clients.
					</Typography>
					<br />
					<Typography>
						If you’re looking for a paralegal or secretarial assistance on a
						particular project, Legalitree can help you too. Just tell us about
						your project and paralegals or legal assistants on our platform will
						respond. You choose the one you like!
					</Typography>
				</Grid>

				<Grid
					item
					xs={12}
					md={4}
					lg={3}
					order={{ xs: 2, md: 3 }}
					align="center"
					sx={{ pt: 5, pb: { xs: 0, md: 5 }, pl: 10, pr: 10 }}
				>
					<img
						src={process.env.REACT_APP_LEGALITREE_AWS_PUBLIC + '/Lawyer.svg'}
						alt="Briefcase and gavel"
						width="185"
					/>
				</Grid>

				<Grid item xs={0} lg={1.5} order={{ xs: 4 }}></Grid>
			</Grid>

			{/* ---------------------------- */}
			{/* Affiliates 					 */}
			{/* ---------------------------- */}
			<Grid
				container
				p={0}
				rowSpacing={0}
				columnSpacing={0}
				justifyContent={'center'}
				alignItems={'center'}
				sx={{ backgroundColor: theme.palette.primary.veryLight }}
			>
				<Grid item xs={0} lg={1.5} order={{ xs: 1 }}></Grid>
				<Grid
					item
					xs={12}
					md={8}
					lg={6}
					sx={{ pt: { xs: 3, md: 5 }, pb: 5, pl: 5, pr: 5 }}
					order={{ xs: 3, md: 3 }}
					align="center"
				>
					<Typography variant={'h6'} color={'primary'}>
						Affiliate Program
					</Typography>
					<Typography>
						The Legalitree Affiliate Program provides an opportunity to be
						rewarded for reaching new clients for Legalitree. If you have a
						large network of potential clients, you can apply to join.
					</Typography>
					<br />
				</Grid>

				<Grid
					item
					xs={12}
					md={4}
					lg={3}
					order={{ xs: 2, md: 2 }}
					align="center"
					sx={{ pt: 5, pb: { xs: 0, md: 5 }, pl: 10, pr: 10 }}
				>
					<img
						src={process.env.REACT_APP_LEGALITREE_AWS_PUBLIC + '/Affiliate.svg'}
						alt="People communicating"
						width="185"
					/>
				</Grid>

				<Grid item xs={0} lg={1.5} order={{ xs: 4 }}></Grid>
			</Grid>
		</>
	);
};

export default Home;
