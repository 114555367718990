import React, { useContext } from 'react';
import { AppBar, Box, Button, Toolbar } from '@mui/material';

import { AuthContext } from '../context/AuthContext';

const AppBars = (props) => {
	//  ------------------------------------------------------------------------
	//  ****** Misc
	//  ------------------------------------------------------------------------
	const auth = useContext(AuthContext);

	//  ------------------------------------------------------------------------
	//  ****** Content
	//  ------------------------------------------------------------------------
	return (
		<>
			{/* App Bar */}
			<AppBar
				position="fixed"
				sx={{
					width: '100%',
					ml: '0px',
				}}
			>
				<Toolbar>
					{/* Spacer */}
					<Box style={{ flexGrow: 1 }}></Box>

					{/* Center Logo */}
					{auth.showAppBarLogo && (
						<Button>
							<img
								src={
									process.env.REACT_APP_LEGALITREE_AWS_PUBLIC +
									'/LT White S.png'
								}
								alt="Legalitree logo"
								height="35"
							/>
						</Button>
					)}

					{/* Spacer */}
					<Box style={{ flexGrow: 1 }}></Box>
				</Toolbar>
			</AppBar>
		</>
	);
};

export default AppBars;
