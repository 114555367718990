import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Box, Grid, Toolbar } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Home from './Home/pages/Home';
import AppBars from './shared/components/AppBars';
import { useAuth } from './shared/hooks/AuthHook';
import { AuthContext } from './shared/context/AuthContext';

const theme = createTheme({
	palette: {
		type: 'light',
		primary: {
			main: '#015366',
			veryLight: '#b5e4fa',
			text: '#FFFFFF',
		},
		secondary: {
			main: '#48aeb9',
			veryLight: '#E0F4F5',
		},
		background: {
			default: '#EEEEEE',
		},
		info: {
			main: '#559944',
		},
	},
	typography: {
		body1: {
			fontSize: '0.9rem',
		},
	},
});

const App = () => {
	const {
		//App Bar Logo
		setShowAppBarLogo,
		showAppBarLogo,
	} = useAuth();

	return (
		<ThemeProvider theme={theme}>
			<AuthContext.Provider
				value={{
					//App Bar Logo
					setShowAppBarLogo: setShowAppBarLogo,
					showAppBarLogo: showAppBarLogo,
				}}
			>
				<Box
					sx={{
						bgcolor: 'background.default',
						minHeight: '100vh',
						minWidth: '100vw',
						ml: -1,
					}}
				>
					<AppBars></AppBars>
					<Box
						component="main"
						sx={{
							width: { md: '100%' },
							ml: { md: '0px' },
						}}
					>
						<Toolbar />
						<Grid container padding={0} mt={-1}>
							<Grid item xs>
								<Home />
							</Grid>
						</Grid>
					</Box>
				</Box>
			</AuthContext.Provider>
		</ThemeProvider>
	);
};

export default App;
