import { useState } from 'react';

export const useAuth = () => {
	//State variables
	const [showAppBarLogo, setShowAppBarLogo] = useState(false);

	//  ------------------------------------------------------------------------
	//  ****** Return
	//  ------------------------------------------------------------------------
	return {
		//App Bar Logo
		setShowAppBarLogo,
		showAppBarLogo,
	};
};
